@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,400;1,500;1,700;1,900&display=swap');

:root {
  --account-center-z-index: 2;
  --onboard-black: black;
  --onboard-connect-sidebar-background: #111827;
  --onboard-connect-sidebar-color: #f9effc;
  --onboard-connect-sidebar-progress-background: #a379ec;
  --onboard-connect-sidebar-progress-color: #7c3aed;
  --onboard-connect-header-background: #ffffff;
  --onboard-connect-header-color: #4700c2;
  --onboard-wallet-button-border-color: #b285ff;
  --onboard-wallet-app-icon-border-color: #b285ff;
  --onboard-wallet-button-background-hover: #ddc9fd;
}

html {
  font-family: 'Roboto', sans-serif;
}

.shadow-purple {
  box-shadow: 0px 0px 60px rgba(19, 111, 250, 0.2);
}

.shadow-purple-sm {
  box-shadow: 0px 0px 28px rgba(19, 111, 250, 0.15);
}

.text-purple-1 {
  color: rgba(17, 24, 39, 1);
}

.bg-purple-gray {
  background: rgb(35, 17, 66);
  background: linear-gradient(
    180deg,
    rgba(17, 24, 39, 1) 10%,
    rgb(39, 23, 74) 80%
  );
}

.bg-purple-only {
  background: rgb(39, 23, 74);
}

.bg-line-purple-gray {
  background: rgb(17, 24, 39);
  background: linear-gradient(
    195deg,
    rgba(17, 24, 39, 1) 0%,
    rgba(39, 23, 74, 1) 16%,
    rgba(17, 24, 39, 1) 32%
  );
}

.bg-purple-blue {
  background: rgb(124, 58, 237);
  background: linear-gradient(
    300deg,
    rgba(124, 58, 237, 1) 30%,
    rgba(19, 111, 250, 1) 100%
  );
}

.gradient-text-purple {
  /* Fallback background color. */
  background-color: #8d56ec;

  /* Gradient. */
  background-image: linear-gradient(
    300deg,
    rgb(155, 117, 222, 1) 30%,
    rgba(19, 111, 250, 1) 100%
  );
  /* Background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  transition: 0.5s;
}

.gradient-text-purple:hover {
  background-image: linear-gradient(
    220deg,
    rgb(148, 102, 227) 30%,
    rgb(0, 100, 251) 100%
  );
}

.tracking-px-n {
  letter-spacing: -1px;
}

.shadow-8xl {
  --tw-shadow: 0px 34px 84px rgba(0, 0, 0, 0.1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.circle1 {
  background-image: url(./assets/tables/circle1.svg);
  background-size: cover;
}

.w-submenu {
  width: 23rem;
}

.frosted-glass {
  background-color: rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(7px);
}

.h-navbar {
  height: 5.5rem;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* tooltip box. "20" means the tooltip arrow's position is 20% from the left */
.tooltip .tooltipText20 {
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 6px;
  position: absolute;
  font-size: smaller;
  z-index: 2;
  bottom: 130%;
  left: 0;
  margin-left: -55%;
}

/* tooltip arrow */
.tooltip .tooltipText20::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 20%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* tooltip box. "50" means the tooltip arrow's position is 50% from the left, 
  "Left" defines the position of the tooltip box on the screen */
.tooltip .tooltipText50Left {
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 6px;
  position: absolute;
  font-size: smaller;
  z-index: 2;
  bottom: 130%;
  left: 0;
  margin-left: -200%;
}

/* tooltip arrow */
.tooltip .tooltipText50Left::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* tooltip box. "50" means the tooltip arrow's position is 50% from the left 
  "Center" defines the position of the tooltip box on the screen */
.tooltip .tooltipText50Center {
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 6px;
  position: absolute;
  font-size: smaller;
  z-index: 2;
  bottom: 130%;
  left: 0;
  margin-left: 0px;
}

/* tooltip arrow */
.tooltip .tooltipText50Center::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* tooltip box. "60" means the tooltip arrow's position is 60% from the left
  "Center" defines the position of the tooltip box on the screen */
.tooltip .tooltipText60Center {
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 6px;
  position: absolute;
  font-size: smaller;
  z-index: 2;
  bottom: 130%;
  left: 0;
  margin-left: -100%;
}

/* tooltip arrow */
.tooltip .tooltipText60Center::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* tooltip box. "70" means the tooltip arrow's position is 70% from the left
  "Right" defines the position of the tooltip box on the screen */
.tooltip .tooltipText70Right {
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 6px;
  position: absolute;
  font-size: smaller;
  z-index: 2;
  bottom: 130%;
  left: 0;
  margin-left: -295%;
}

/* tooltip arrow */
.tooltip .tooltipText70Right::after {
  position: absolute;
  content: '';
  top: 100%;
  left: 70%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltipText20,
.tooltip:hover .tooltipText50Left,
.tooltip:hover .tooltipText50Center,
.tooltip:hover .tooltipText60Center,
.tooltip:hover .tooltipText70Right {
  @apply hidden md:visible;
}

.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.-z-1 {
  z-index: -1;
}

.-z-2 {
  z-index: -2;
}

.-z-3 {
  z-index: -3;
}

.border-top-left {
  border-top-left-radius: 4.4rem;
}

.remove-scrollbar {
  scrollbar-width: none;
}

.skew {
  display: none;
}

.skewed-top-right .skew-top.mr-for-radius {
  display: block;
}

.skewed-top-left .skew-top.ml-for-radius {
  display: block;
  margin-left: 4.5rem;
}

.skewed-bottom-right .skew-bottom.mr-for-radius {
  display: block;
}

.skewed-bottom-left .skew-bottom.ml-for-radius {
  display: block;
}

.skewed-top-right .radius-for-skewed {
  border-top-right-radius: 6rem;
}

.skewed-bottom-right .radius-for-skewed {
  border-bottom-right-radius: 6rem;
}

.skewed-bottom-left .radius-for-skewed {
  border-bottom-left-radius: 6rem;
}

.mr-for-radius {
  margin-right: 5.4rem;
}

.min-h-24 {
  min-height: 4rem;
}

/* slider */
/* SliderWithPercentage.css */
.slider-container {
  position: relative;
}

.slider {
  -webkit-appearance: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  @apply bg-gray-300 outline-none h-1 w-full rounded-lg;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  @apply appearance-none w-14 h-14 bg-transparent border-none cursor-pointer;
}

.slider::-moz-range-thumb {
  border-radius: 50%;
  @apply appearance-none w-14 h-14 bg-transparent border-none cursor-pointer;
}

.value-label {
  /* top: 135%; */
  left: 50%; /* Start at the center */
  transform: translate(-50%, -100%); /* Adjust for exact positioning */
  border-radius: 50%;
  pointer-events: none; /* Ignore pointer events so the label doesn't interfere with the slider */
  border-color: rgb(24, 116, 254);
  @apply bg-gray-800 border text-white absolute flex
    items-center justify-center w-14 h-14 rounded-full text-xs;
}

/* ofa input setting */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms, transform 300ms;
}

/* ofa set button */
@keyframes shake {
  0% {
    transform: translateX(0);
    border-color: red;
    background-color: red;
  }
  25% {
    transform: translateX(-5px);
    border-color: red;
    background-color: red;
  }
  50% {
    transform: translateX(5px);
    border-color: red;
    background-color: red;
  }
  75% {
    transform: translateX(-5px);
    border-color: red;
    background-color: red;
  }
  100% {
    transform: translateX(0);
    border-color: red;
    background-color: red;
  }
}

.shake-animation {
  animation: shake 0.5s;
}
